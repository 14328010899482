<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Inspections</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'inspectionForm', params: { id: 0 } }"
                        >
                            New Inspection
                        </v-btn>
                    </template>
                    <span>Inspection</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Panel</label>
                <v-combobox
                    v-model="filter.panelSelected"
                    :items="listConstructionPanel"
                    item-text="panelDescription"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters(); filterStages()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Stage</label>
                <v-combobox
                    v-model="filter.stageSelected"
                    :items="listConstructionStagesFiltered" 
                    item-text="stageDescription"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <label>Responsible</label>
                <v-combobox
                    v-model="filter.responsibleSelected"
                    :items="listUser"
                    item-text="name"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Status</label>
                <v-combobox
                    v-model="filter.statusSelected"
                    :items="listStatus" 
                    item-text="statusDescription"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="6"
                lg="2"
                md="6"
                sm="6"
            >
                <v-menu
                    ref="mnuTargetDateStart"
                    v-model="mnuTargetDateStart"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <label>Target Date Start</label>
                        <v-text-field
                            v-model="filter.targetDateStartFormatted"
                            prepend-inner-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            outlined
                            single-line
                            v-on="on"
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filter.targetDateStart"
                        no-title
                        @input="mnuTargetDateStart = false"
                        color="primary lighten-1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col 
                cols="6"
                lg="2"
                md="6"
                sm="6"
            >
                <v-menu
                    ref="mnuTargetDateEnd"
                    v-model="filter.mnuTargetDateEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <label>Target Date End</label>
                        <v-text-field
                            v-model="filter.targetDateEndFormatted"
                            prepend-inner-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            outlined
                            single-line
                            v-on="on"
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filter.targetDateEnd"
                        no-title
                        @input="mnuTargetDateEnd = false"
                        :min="filter.targetDateStart"
                        color="primary lighten-1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                
                <v-data-table
                    style="margin-top: 10px;"
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                            small
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.targetDateFormatted="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.targetDateFormatted }}
                        </label>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'inspectionForm'"
                            :showButtons="{
                                edit: item.status === 0 ? true : false,
                                delete: false,
                                cancel2: item.status === 0 ? true : false,
                                schedule: item.status === 0 ? true : false,
                                markDoneUnDone: item.status === 1 ? true : false,
                                markFailed: item.status === 1 ? true : false,
                                check: item.status === 2 && (userLoggedGetters.profile.systemAdmin == 1 || userLoggedGetters.profile.aproveInspectionWithoutRevision == 1) ? true : false
                            }"
                            @confirmCancel2="confirmCancel"
                            @confirmSchedule="confirmSchedule"
                            @confirmMarkAsFailed="confirmMarkAsFailed"
                            @confirmDoneUnDone="confirmDone"
                            @confirmCheck="confirmCheck"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <v-dialog
            v-model="showDialogDate"
            transition="dialog-top-transition"
            persistent
            width="900"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    {{ titleDate }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            v-if="typeSchedule != 'CR'"
                            cols="12"
                            :lg="typeSchedule == 'C' || typeSchedule == 'R' ? 12 : 6"
                            :md="typeSchedule == 'C' || typeSchedule == 'R' ? 12 : 6"
                            sm="12"
                        >
                            <label>Select Date</label>
                            <v-menu
                                ref="mnuTodayDate"
                                v-model="mnuTodayDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="todayDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="todayDate = parseDate(todayDateFormatted)"
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="todayDate"
                                    no-title
                                    @input="mnuTodayDate = false"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col 
                            v-if="typeSchedule == 'S'"
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Responsible</label>
                            <v-combobox
                                v-model="responsibleSelected"
                                :items="listUser"
                                item-text="name"
                                item-value="id"
                                single-line
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                        <v-col 
                            v-if="typeSchedule == 'F'"
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Fee</label>
                            <v-text-field
                                v-model="fee" 
                                v-formatMoney="moneyFormat"
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="typeSchedule == 'C' || typeSchedule == 'R' || typeSchedule == 'CR' || typeSchedule == 'F'"> 
                        <v-col
                            cols="12"
                        >
                            <canvas id="canvas" hidden></canvas>
                            <label>Pictures</label>
                            <v-file-input
                                v-model="filesPhotos" 
                                accept="image/*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Click here to add"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                dense
                                :disabled="typeSchedule == 'CR'"
                                @change="resizePreviewImage(filesPhotos, photos)"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row 
                        v-if="photos.length > 0 && (typeSchedule == 'C' || typeSchedule == 'R' || typeSchedule == 'CR' || typeSchedule == 'F')"
                        style="padding-bottom: 20px;"
                    >
                        <v-col
                            v-for="(itemPhoto, index) in photos.filter(pht => pht.deleted == false)"
                            :key="index"
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <v-card 
                                max-width="200"
                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                            >
                                <v-img
                                    :src="itemPhoto.src"
                                    :lazy-src="itemPhoto.src"
                                    aspect-ratio="1"
                                    max-height="200"
                                    max-width="200"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                <v-icon v-if="typeSchedule != 'CR'" class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(photos, itemPhoto)">mdi mdi-delete</v-icon>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="typeSchedule == 'F' || typeSchedule == 'C' || typeSchedule == 'CR' || typeSchedule == 'R'">
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Description</label>
                            <v-textarea
                                v-model="reason"
                                :rules=[validations.required]
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                                :disabled="typeSchedule == 'CR'"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2"
                            style="background-color: var(--color__silver) !important; color: #ffffff !important"
                            outlined
                            @click="showDialogDate = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            v-if="typeSchedule == 'CR'"
                            style="background-color: var(--color__red) !important; color: #ffffff !important;"
                            class="mx-2"
                            outlined
                            @click="confirmDenied()"
                        >
                            Denied
                        </v-btn>

                        <v-btn
                            class="mx-2"
                            outlined
                            @click="confirmDate()"
                        >
                            {{ typeSchedule == 'CR' ? 'Approved' : 'Confirm' }}
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { formaterDecimalBRServer } from '@/utilities/Utils';
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT, URL_ATTACHMENT } from "@/utilities/Enums";
    import * as moment from 'moment';

    export default ({

        components: {
            ActionList,
            ActionDialog
        },

        mixins: [Helpers],

        data: vm => ({

            URL_ATTACHMENT,

            id: 0,
            statusId: 0,
            responsibleSelected: null,
            idUserResponsible: 0,
            fee: "$ 0,00",
            reason: '',

            filesPhotos: [],
            photos: [],

            listPhotosDelete: [],
            itemPhotoDelete: {},

            loading: false,

            filter: {
                fastSearch: '',
                panelSelected: null,
                stageSelected: null,
                responsibleSelected: null,
                statusSelected: null,
                targetDateStart: moment().clone().startOf('month').format('YYYY-MM-DD'),
                targetDateEnd: moment().clone().endOf('month').format('YYYY-MM-DD'),
                targetDateStartFormatted: moment().clone().startOf('month').format('MM/DD/YYYY'),
                targetDateEndFormatted: moment().clone().endOf('month').format('MM/DD/YYYY'),
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Created By", value: "userNameRegister", sortable: true },
                { text: "Project", value: "projectDescription", sortable: true },
                { text: "Phase", value: "panelAndStageDescription", sortable: true },
                { text: "Description", value: "description", sortable: true },
                { text: "Target", value: "targetDateFormatted", sortable: true },
                { text: "Responsible", value: "userNameResponsible", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" },
            ],

            options: { rowsPerPage: 10, page: 1 },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },
            
            listInspections: [],
            listInspectionsFiltered: [],
            listUser: [],
            listContractor: [],
            listStatus: [],
            listConstructionPanel: [],
            listConstructionStages: [],
            listConstructionStagesFiltered: [],

            mnuTargetDateStart: false,
            mnuTargetDateEnd: false,

            titleDate: '',
            todayDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            todayDateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            mnuTodayDate: false,

            showDialogDate: false,
            typeSchedule: '', 
            
            validations: {
                required: required,
            }
        }),

        watch: {
            todayDate () {
                this.todayDateFormatted = this.formatDate(this.todayDate)
            },

            "filter.targetDateStart" () {
                this.filter.targetDateStartFormatted = this.formatDate(this.filter.targetDateStart)
                this.filterRegisters();
            },

            "filter.targetDateEnd" () {
                this.filter.targetDateEndFormatted = this.formatDate(this.filter.targetDateEnd)
                this.filterRegisters();
            },
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listInspectionsFiltered.filter((serviceFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var dateRegisterFormatted = serviceFilter.dateRegisterFormatted != null && serviceFilter.dateRegisterFormatted != undefined ? serviceFilter.dateRegisterFormatted.toLowerCase().match(filter) : ""
                    var projectDescription = serviceFilter.projectDescription != null && serviceFilter.projectDescription != undefined ? serviceFilter.projectDescription.toLowerCase().match(filter): ""
                    var userNameRegister = serviceFilter.userNameRegister != null && serviceFilter.userNameRegister != undefined ? serviceFilter.userNameRegister.toLowerCase().match(filter): ""
                    var panelAndStageDescription = serviceFilter.panelAndStageDescription != null && serviceFilter.panelAndStageDescription != undefined ? serviceFilter.panelAndStageDescription.toLowerCase().match(filter) : ""
                    var description = serviceFilter.description != null && serviceFilter.description != undefined ? serviceFilter.description.toLowerCase().match(filter) : ""
                    var targetDateFormatted = serviceFilter.targetDateFormatted != null && serviceFilter.targetDateFormatted != undefined ? serviceFilter.targetDateFormatted.toLowerCase().match(filter): ""
                    var userNameResponsible = serviceFilter.userNameResponsible != null && serviceFilter.userNameResponsible != undefined ? serviceFilter.userNameResponsible.toLowerCase().match(filter): ""
                    var statusDescription = serviceFilter.statusDescription != null && serviceFilter.statusDescription != undefined ? serviceFilter.statusDescription.toLowerCase().match(filter) : ""
                    var id = serviceFilter.id.toString().match(filter);

                    if(dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else if(projectDescription != null) { return projectDescription; } 
                    else if(userNameRegister != null) { return userNameRegister; } 
                    else if(panelAndStageDescription != null) { return panelAndStageDescription; } 
                    else if(description != null) { return description; } 
                    else if(targetDateFormatted != null) { return targetDateFormatted; } 
                    else if(userNameResponsible != null) { return userNameResponsible; } 
                    else if(statusDescription != null) { return statusDescription; }
                    else { return id; }
                });
            }
        },

        methods: {

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__status_pending)';
                        break;

                    case 1:
                        color = 'var(--color__main)';
                        break;

                    case 2:
                        color = 'var(--color__status_pending)';
                        break;

                    case 3:
                        color = 'var(--color__completed)';
                        break;

                    case 4:
                    case 5:
                        color = 'var(--color__red)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async getRegisters() {

                this.listInspections = await this.$store.dispatch("inspectionModule/List");
                this.listInspectionsFiltered = [...this.listInspections];
                let listUserResponse = await this.$store.dispatch("userModule/List");
                this.listUser = listUserResponse.filter(usr => usr.constructionSupervisor == 1);
                this.listConstructionPanel = await this.$store.dispatch("serviceModule/ListConstructionPanel");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");
                this.listStatus = await this.$store.dispatch("inspectionModule/ListStatus");
                this.filterRegisters();
            },
            
            async confirmPhotoDelete(listPhotos, itemPhotoToDelete) {

                this.listPhotosDelete = listPhotos;
                this.itemPhotoToDelete = itemPhotoToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this image [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPhotoToDelete.deleted = true;
            },

            confirmCancel(id) {

                this.statusId = 4;
                this.id = id;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will CANCEL this Work Order, confirm your decision?',
                    methodConfirm: this.updateStatus,
                    params: id
                };

            },

            clearFields() {
                this.filesPhotos = [];
                this.photos = [];
                this.fee = "$ 0,00";
                this.reason = "";
            },

            confirmSchedule(id) {
                this.clearFields();
                this.titleDate = 'Schedule'
                this.statusId = 1;
                this.typeSchedule = "S";
                this.id = id;
                this.showDialogDate = true;
            },

            confirmMarkAsFailed(id) {
                this.clearFields();
                this.titleDate = 'Mark as Failed'
                this.statusId = 3;
                this.typeSchedule = "F";
                this.id = id;
                this.showDialogDate = true;
            },

            confirmDone(id) {
                this.clearFields();

                if (this.userLoggedGetters.profile.systemAdmin == 1 || this.userLoggedGetters.profile.aproveInspectionWithoutRevision == 1) {
                    this.statusId = 3;
                    this.titleDate = 'Mark as Done';
                    this.typeSchedule = "C";
                }
                else {
                    this.statusId = 2;
                    this.titleDate = 'Submit for Review';
                    this.typeSchedule = "R";
                }
                this.id = id;
                this.showDialogDate = true;
            },

            async confirmCheck(id) {
                this.clearFields();

                let itemInspection = this.listInspections.filter (ins => ins.id == id)[0];
                
                let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.INSPECTION.value, idKey: id });
                
                let photos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.INSPECTION.value);
                if (photos != null && photos != undefined) {

                    photos.forEach(itemPicture => {

                        this.photos.push({
                            id: itemPicture.id,
                            file: new File([], itemPicture.fileName),
                            fileName: itemPicture.fileName,
                            fileType: itemPicture.fileType,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPicture.file}`
                        });
                    })
                }

                this.statusId = 3;
                this.titleDate = 'Check Review';
                this.typeSchedule = "CR";
                this.id = id;
                this.reason = itemInspection.reason;
                this.showDialogDate = true;
            },

            async confirmDenied() {
                this.statusId = 1;
                this.typeSchedule = "CR";
                this.confirmDate();
            },

            confirmDate() {

                let textConfirmation = '';
                let valid = true;
                this.idUserResponsible = 0;

                if (this.typeSchedule == "S") {
                    textConfirmation = 'SCHEDULE';

                    if (this.responsibleSelected != null && this.responsibleSelected != undefined) {
                        this.idUserResponsible = this.responsibleSelected.id;
                    }
                    else {
                        valid = false;
                        this.showToast("error", "Error!", "Responsible is required for this action!");
                    }
                }
                else if (this.typeSchedule == "C") {
                    textConfirmation = 'FINISH'
                }
                else if (this.typeSchedule == "R") {
                    textConfirmation = 'SUBMIT FOR REVIEW'
                }
                else if (this.typeSchedule == "F") {
                    textConfirmation = 'mark as FAILED'
                }
                else if (this.typeSchedule == "CR" && this.statusId == 3) {
                    textConfirmation = 'APPROVE'
                }
                else if (this.typeSchedule == "CR" && this.statusId == 1) {
                    textConfirmation = 'DENY'
                }

                if (this.typeSchedule == "F") {
                    if (this.reason.toString().trim() == "") {
                        valid = false;
                        this.showToast("error", "Error!", "Reason is required for this action!");
                    }
                }

                if (valid == true) {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `You will ${textConfirmation} this inspection, confirm your decision?`,
                        methodConfirm: this.updateStatus,
                        params: this.id
                    };
                }
            },

            async uploadPhotos(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.INSPECTION.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async savePhotos() {

                let allResult = []

                this.photos.forEach(async itemPhoto => {

                    let result = await this.uploadPhotos(itemPhoto.id, this.id, itemPhoto.file, TYPE_DOCUMENT.INSPECTION.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.INSPECTION.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload pictures: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async updateStatus() {

                this.showLoading();

                let sendReason = this.typeSchedule == "F" || this.typeSchedule == "C" || this.typeSchedule == "R";

                let updateStatusRequest = {
                    id: this.id,
                    status: this.statusId,
                    date: this.todayDate,
                    idUserResponsible: this.idUserResponsible,
                    fee: formaterDecimalBRServer(this.fee),
                    reason: sendReason == true ? this.reason : null
                }

                let result = await this.$store.dispatch("inspectionModule/UpdateStatus", updateStatusRequest);

                if (sendReason == true) {
                    if (await this.savePhotos()) {

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.showDialogDate = false;
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
                else {
                    if (result.success) {
                        this.showToast("success", "Success!", result.message);
                        this.showDialogDate = false;

                    }
                    else {
                        this.showToast("error", "Error!", result.message);
                    }
                }

                this.getRegisters();
                this.showDialogDate = false;

                this.hideLoading();
            },

            filterStages() {

                if (this.filter.panelSelected != null && this.filter.panelSelected != undefined) {
                    this.filter.stageSelected = null;
                    this.listConstructionStagesFiltered = this.listConstructionStages.filter(stage => stage.idPanel == this.filter.panelSelected.id);
                }
            },

            async filterRegisters() {

                let idPanel = 0;
                let idStage = 0;
                let idResponsible = 0;
                let idStatus = 0;
                let listFiltered = [...this.listInspections];

                if (this.filter.panelSelected != null && this.filter.panelSelected != undefined) {
                    idPanel = this.filter.panelSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.idPanel == idPanel);
                }

                if (this.filter.stageSelected != null && this.filter.stageSelected != undefined) {
                    idStage = this.filter.stageSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.idStage == idStage);
                }

                if (this.filter.responsibleSelected != null && this.filter.responsibleSelected != undefined) {
                    idResponsible = this.filter.responsibleSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.idUserResponsible == idResponsible);
                }

                if (this.filter.statusSelected != null && this.filter.statusSelected != undefined) {
                    idStatus = this.filter.statusSelected.id;
                    listFiltered = listFiltered.filter(wor => wor.status == idStatus);
                }

                if (this.filter.targetDateStart != null && this.filter.targetDateStart != undefined && 
                    this.filter.targetDateEnd != null && this.filter.targetDateEnd != undefined) {
                    listFiltered = listFiltered.filter(wor => (wor.targetDate >= this.filter.targetDateStart && wor.targetDate <= this.filter.targetDateEnd) || (wor.targetDate == null || wor.targetDate == undefined));
                }

                this.listInspectionsFiltered = listFiltered;
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

</style>